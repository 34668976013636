<template>
  <v-btn :loading="loading" class="d-flex align-center font-weight-bold rounded-pill justify-center pa-0 fill-width fill-height green lighten-4" light outlined @click.prevent="IMPORT_DATA()">
    <v-icon small class="mr-1">
      mdi-file-import
    </v-icon>
    Import Awb
  </v-btn>
</template>

<script>
export default {
  data: () => ({
    dialogConfirmUpload: false,
    isError: [],
    dataLoaded: false,
    file: null,
    sheets: {},
    loading: false,
    sheetSelected: null
  }),
  methods: {
    IMPORT_DATA () {
      this.sheets = []
      this.sheetSelected = null
      let u = document.getElementById('file-uploader')
      if (u) {
        u.remove()
      }
      setTimeout(() => {
        u = document.createElement('input')
        u.setAttribute('type', 'file')
        u.setAttribute('id', 'file-uploader')
        u.setAttribute('class', 'file-uploader')
        u.setAttribute('accept', '.xls,.xlsx')
        u.addEventListener('change', (e) => {
          const f = e.target.files ? e.target.files[0] : null
          this.UPLOAD(f)
        })
        document.body.appendChild(u)
        u.click()
      }, 100)
    },
    async UPLOAD (file) {
      const d = new FormData()
      if (file) {
        d.append('file', file)
      } else {
        this.$store.dispatch('TOAST', { show: true, message: 'File undefined!' })
        return false
      }
      this.loading = true
      const x = await this.$store.dispatch('logistic/IMPORT_AWB', d)
      if (x && x.data?.data?.ok) {
        this.$store.dispatch('TOAST', { show: true, message: `${x.data?.data?.ok.length} AWB's Imported!` })
        this.$emit('reload', true)
      } else {
        this.$store.dispatch('TOAST', { show: true, message: 'Something went wrong!' })
      }
      this.loading = false
    }
  }
}
</script>
